@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,300&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Tenali+Ramakrishna&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merienda:wght@400;700;800;900&display=swap');
body{
  font-family: 'Noto Sans', sans-serif!important;
}

p{
  font-family: 'Noto Sans', sans-serif!important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.f-16{
  font-size: 16px!important;
}
.f-18{
  font-size: 18px!important;
}
.f-20{
  font-size: 20px!important;
}
.f-22{
  font-size: 22px!important;
}
.f-24{
  font-size: 24px!important;
}
.f-26{
  font-size: 26px!important;
}
.fw-300{
  font-weight: 300;
}
.fw-400{
  font-weight: 400;
}
.fw-500{
  font-weight: 500;
}
.fw-600{
  font-weight: 600;
}
.fw-700{
  font-weight: 700;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.contact-form {
  padding: 50px 0;
}
.section-padding{
  padding: 60px 0;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.heading-006 {
  display: inline-block;
  position: relative;
  padding: 0 2.5em;
  color: #333333;
  text-transform: uppercase;
}

.heading-006::before,
.heading-006::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 50%;
  width: 45px;
  height: 3px;
  background-color: #2589d0;
}

.heading-006::before {
  left: 0;
}
.nav-item a{
  color: #fff!important;
}
.heading-006::after {
  right: 0;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.list-005 {
  background-color: #f2f2f2;
}

.list-005 div {
  display: block;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 10px 10px;
  background-color: #2589d0;
  color: #fff;
  font-weight: 600;
}

.list-005 ul {
  list-style-type: disc;
  margin: 0;
  padding: 1em 1em 1em 1em;
}

.list-005 li {
  padding: .3em .3em .3em 0;
  list-style: none;
}

.list-005 li::before {
  content: "✓"; /* Unicode tick symbol */
    color: #007bff; /* Light blue color */
    margin-right: 8px;
    font-weight: 800; /* Adjust the spacing between the tick and the list item text */
}
.footer-002 {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 25px 10px 10px;
  background-color: #2589d0;
}

.footer-002__logo {
  width: 130px;
  height: 30px;
}

.footer-002__list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 1.5em;
  list-style-type: none;
  margin: 0 0 .5em;
  padding: .5em;
}

.footer-002__sns-icon {
  width: 21px;
  height: 21px;
}

.footer-002__copyright {
  margin: 0;
  color: #ffffffb3;
  font-weight: 200;
  font-size: .8em;
}
.breadcrumb-003 {
  display: flex;
  gap: 0 20px;
  list-style: none;
  padding: 6px 0 6px 18px;
  border-radius: 3px;
  overflow: hidden;
  background-color: #edf2f6;
  font-size: .9em;
}

.breadcrumb-003 li {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 20px;
}

.breadcrumb-003 li:first-child a::before {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 4px;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M11.7%207.5%204%2013.8v6.8c0%20.4.3.7.7.7h4.7c.4%200%20.7-.3.7-.7v-4c0-.4.3-.7.7-.7h2.7c.4%200%20.7.3.7.7v4c0%20.4.3.7.7.7h4.7c.4%200%20.7-.3.7-.7v-6.8l-7.7-6.3h-.9zm12.1%204.4L20.3%209V3.2c0-.3-.2-.5-.5-.5h-2.3c-.3%200-.5.2-.5.5v2.9L13.3%203c-.7-.6-1.8-.6-2.5%200L.2%2011.8c-.2.2-.3.5-.1.7l1.1%201.3c.2.2.5.2.7.1l9.8-8.1h.6l9.8%208.1c.2.2.5.1.7-.1l1.1-1.3c.2-.2.1-.5-.1-.6z%22%20style%3D%22fill%3A%23555%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  vertical-align: text-bottom;
  content: '';
}

.breadcrumb-003 li:not(:last-child)::before,
.breadcrumb-003 li:not(:last-child)::after{
  position: absolute;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  content:'';
}

.breadcrumb-003 li:not(:last-child)::before{
  right: 0;
  border-left: 10px solid #c6cdd3;
}

.breadcrumb-003 li:not(:last-child)::after{
  right: 1px;
  border-left: 10px solid #edf2f6;
}

.breadcrumb-003 a {
  color: #555;
  text-decoration: none;
}
.list-011 {
  list-style-type: disc;
  padding: 1em 1em 1em 2.5em;
  /* border-left: 5px solid #2589d0; */
  background-color: #e0efff;
}

.list-011 li {
  padding: .3em .3em .3em 0;
}

.list-011 li::marker {
  color: #4faef1e7;
  font-size: 1.1em;
}
.heading-027 {
  display: flex;
  align-items: center;
  gap: 0 7px;
  color: #333333;
  font-weight: 600;
}

.heading-027::before {
  width: 20px;
  height: 20px;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%232589d0%22%20d%3D%22M8.2%2C20.6l-7.8-7.8c-0.5-0.5-0.5-1.2%2C0-1.7L2%2C9.4c0.5-0.5%2C1.2-0.5%2C1.7%2C0L9%2C14.7L20.3%2C3.4c0.5-0.5%2C1.2-0.5%2C1.7%2C0l1.7%2C1.7%20c0.5%2C0.5%2C0.5%2C1.2%2C0%2C1.7L9.8%2C20.6C9.4%2C21.1%2C8.6%2C21.1%2C8.2%2C20.6L8.2%2C20.6z%22%2F%3E%3C%2Fsvg%3E');
  content: '';
}
.box-010 {
  position: relative;
  max-width: 100%;
  margin: 1.5em auto;
  padding: 2.5em 1.5em 1.5em;
  box-shadow: 0 2px 3px rgb(0 0 0 / 20%);
  background-color: #e0efff;
  color: #333333;
}

.box-010 span {
  position: absolute;
  top: -15px;
  transform: translateX(-.3em) rotate(-5deg);
  padding: .5em 2em;
  border-right: 2px dotted rgb(0 0 0 / 10%);
  border-left: 2px dotted rgb(0 0 0 / 10%);
  box-shadow: 0 0 5px rgb(0 0 0 / 20%);
  background-color: rgb(255 255 255 / 40%);
  font-weight: 600;
  font-size: 20px;
}

.box-010 p {
  margin: 0;
}
.box-010 p{
  line-height: 40px;
}

.sitename{
  color: #fff!important;
  font-family: 'Merienda', cursive;
  font-weight: 800;
  font-size: 22px;
}
.blog-card {
  overflow: hidden; /* Ensure that the zoom effect stays within the card boundaries */
  transition: transform 0.3s ease; /* Apply smooth transition on transform */
}

.zoom-card {
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease;
  transform-origin: center center; /* Set the origin point for zoom */
}

.zoom-card:hover {
  transform: scale(1.1); /* Apply the zoom effect on hover */
}
.blog-title:hover{
  text-decoration: underline;
  cursor: pointer;
  color: #2589d0;
}
.bottom-margin-20{
  margin-bottom: 20px;
}
.blog-description li:before{
  content : "️️🢚";
  margin-right: 5px;
  color: #2589d0;
  font-size: 20px;
}

.blog-description li{
  list-style: none;
}
.blog-description table{
  width: 100%!important;
}
.blog-description ul{
  padding: 0;
}

.custom-radio-label {
  display: inline-block;
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-radio-label.active {
  background-color: #333; /* Change this to your desired dark color */
  color: #fff; /* Change text color to contrast with the dark background */
}
.sec-pad-bottom{
  padding-bottom: 40px;
}
.react-calendar{
  width: 100%!important;
}
.appointment .form-control{

border: 1px solid #105c7199;
line-height: 1.9rem;

}