@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,300&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Tenali+Ramakrishna&display=swap');

body{
    font-family: 'Noto Sans', sans-serif!important;
}
.nav-item:hover .nav-link {
    position: relative;
  }
  .nav-item
  {
    font-family: 'Noto Sans', sans-serif;
  }
  
  .nav-item:hover .nav-link::before {
    content: "";
    position: absolute;
    bottom: -2px; /* Adjust this value to control the distance of the underline from the text */
    left: 0;
    width: 100%;
    height: 2px; /* Adjust this value to control the height of the underline */
    background-color: #dfeeff; /* Set the color of the underline */
    transition: height 0.5s; /* Set the transition duration for the underline */
  }

.blink_me {
    animation: blinker 1s linear infinite;
    width: 15px;
    height: 15px;
    display: inline-block;
    border: 1px solid #5aff5a;
    background-color: #5aff5a;
    border-radius: 100%;
  }
  @keyframes blinker {
    30% {
      opacity: 0.4;
    }
  }
  .whyworkul li{
    list-style: none;
    line-height: 30px;
    font-family: 'Noto Sans', sans-serif;
  }
  .whyworkul li::before{
    content: "✓"; /* Unicode tick symbol */
    color: #007bff; /* Light blue color */
    margin-right: 8px;
    font-weight: 800; /* Adjust the spacing between the tick and the list item text */
  
  }
  .whyworkul{
    padding-left: 0;
  }
  .whywork-container {
    display: flex;
    justify-content: space-between; /* Adjust as needed (space-around, space-evenly, etc.) */
  }
  
  .whywork {
    width: 50%; /* Adjust the width as needed to control the spacing between the lists */
  }
  
  .whyworkul {
    list-style: none;
    padding: 0;
  }

  .sub-heading{
    text-transform: capitalize;
  }
  .basic-sontact li{
    display: inline-block;
    margin-right: 40px;
    font-size: 20px;
    
  }
  .basic-sontact{
    padding:0;
    margin-bottom:40px;
  }
  .balloon-009 {
    display: flex;
    /* justify-content: center; */
    align-items: start;
    gap: 0 22px;
}

.balloon-009 img {
    max-width: 70px;
    height: 100%;
    border: 3px solid #e6edf3;
    border-radius: 50%;
}

.balloon-009 p {
    position: relative;
    max-width: 300px;
    margin: 3px 0 0;
    padding: .8em 1em;
    border-radius: 5px;
    background-color: #e0efff;
    color: #333333;
}

.balloon-009 p::before {
    position: absolute;
    left: -15px;
    width: 15px;
    height: 30px;
    background-color: #e0efff;
    clip-path: polygon(0 50%, 100% 0, 100% 100%);
    content: '';
}
